import { useState } from 'react';
import React, { useRef } from 'react';
import styles from './Information.module.css';
import Modal from '../../components/Modal/Modal';
import lgpdPdf from '../../documents/LGPD.pdf';

const Information = () => {
  const form = useRef();
  const [showLgpdModal, setShowLgpdModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    socialName: '',
    cpf: '',
    phone: '',
    email: '',
    street: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    
    try {
      
      if(formData.fullName == ''){
        throw new Error('Campo Nome Completo não informado!');
      }
      else if (formData.email ==''){
        throw new Error('Campo Email não informado!');
      }
      else if (formData.phone ==''){
        throw new Error('Campo Telefone não informado!');
      }
      else if (formData.cpf ==''){
        throw new Error('Campo CPF não informado!');
      }
      else if (formData.street ==''){
        throw new Error('Campo Rua não informado!');
      }
      else if (formData.complement ==''){
        throw new Error('Campo Complemento não informado!');
      }
      else if (formData.neighborhood ==''){
        throw new Error('Campo Bairro não informado!');
      }
      else if (formData.city ==''){
        throw new Error('Campo Cidade não informado!');
      }
      else if (formData.state ==''){
        throw new Error('Campo Estado não informado!');
      }
      else if (formData.zipCode ==''){
        throw new Error('Campo CEP não informado!');
      }                                            

      const response = await fetch(process.env.REACT_APP_API_SENDMAIL_INFORMATION, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY_SENDMAIL
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          fullName: '',
          socialName: '',
          cpf: '',
          phone: '',
          email: '',
          street: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
          zipCode: '',
        });
        setSuccessMessage('✓ Mensagem enviada com sucesso!');
      } else {
        throw new Error('Falha na requisição');
      }
    } catch (error) {
      console.error('FAILED...', error);
      setSuccessMessage('⚠ Falha no envio da mensagem. Por favor, tente novamente mais tarde ou entre em contato através de outros canais.');
    }
  };

  const handleReset = () => {
    setFormData({
      fullName: '',
      socialName: '',
      cpf: '',
      phone: '',
      email: '',
      street: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      zipCode: '',
    });
    setSuccessMessage('');
  };

  const handleCloseModal = () => {
    setSuccessMessage('');
  };

  const handleLgpdClick = () => {
    setShowLgpdModal(true);
  };

  return (
    <section className={styles.informationSection}>
      <h1 className={styles.sectionTitle}>Informações</h1>
      <div className={styles.underline} />
      <p className={styles.description}>
        Atualização de cadastro do associado
      </p>
      <div className={styles.formSectionLabel}>
          Dados Pessoais
        </div>
      {successMessage && <Modal message={successMessage} onClose={handleCloseModal} />}
      {showLgpdModal && <Modal pdfUrl={lgpdPdf} onClose={() => setShowLgpdModal(false)} />}

      <form ref={form} className={styles.form} onSubmit={sendEmail} onReset={handleReset}>
        <div className={styles.formRow}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="fullName"
              placeholder="Nome completo"
              value={formData.fullName}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="socialName"
              placeholder="Nome social"
              value={formData.socialName}
              onChange={handleChange}
              className={styles.input}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="cpf"
              placeholder="CPF"
              value={formData.cpf}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              type="tel"
              name="phone"
              placeholder="Telefone"
              value={formData.phone}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
        </div>

        <div className={styles.formSectionLabel}>
          Endereço
        </div>

        <div className={styles.formRow}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="street"
              placeholder="Rua"
              value={formData.street}
              onChange={handleChange}
              className={styles.inputLarge}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="complement"
              placeholder="Complemento"
              value={formData.complement}
              onChange={handleChange}
              className={styles.inputSmall}
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="neighborhood"
              placeholder="Bairro"
              value={formData.neighborhood}
              onChange={handleChange}
              className={styles.inputMedium}
              required
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="city"
              placeholder="Cidade"
              value={formData.city}
              onChange={handleChange}
              className={styles.inputLarge}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="state"
              placeholder="UF"
              value={formData.state}
              onChange={handleChange}
              className={styles.inputSmall}
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="zipCode"
              placeholder="CEP"
              value={formData.zipCode}
              onChange={handleChange}
              className={styles.inputMedium}
              required
            />
          </div>
        </div>

        <p className={styles.urgency}>
          Em conformidade com a Lei nº 13.709/2018 – LGPD (Lei Geral de Proteção de Dados) suas informações serão preservadas.
          <br/>
          <a onClick={handleLgpdClick} style={{ cursor: 'pointer' }}>
            Detalhes e Aspectos gerais LGPD, clique aqui!
          </a>
        </p>

        <div className={styles.buttonGroup}>
          <button type="submit" className={styles.submitButton}>
            Enviar
          </button>
          <button type="reset" className={styles.resetButton}>
            Limpar
          </button>
        </div>
      </form>
    </section>
  );
};

export default Information;
